
import { defineComponent, PropType, ref, watch } from 'vue'
import { ITableItem } from '../types'
import MainSearch from '@/components/main-header/src/main-search.vue'

export default defineComponent({
  components: { MainSearch },
  emits: ['myAddShop', 'handleCurrentChange'],
  props: {
    tableItems: {
      type: Array as PropType<ITableItem[]>,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    totalPage: {
      type: Number,
      default: 100,
    },
  },
  setup(props, { emit }) {
    let GoodsIds: number[] = []
    // 显示
    let dialogVisible = ref(false)
    let tableDatas = ref()
    // 侦听数据变化
    watch(
      () => props.tableData,
      (newValue, oldValue) => {
        // console.log(newValue, oldValue)
        tableDatas.value = newValue
      },
      {
        immediate: true,
      },
    )
    // 发送事件
    const addShop = () => {
      emit('myAddShop', GoodsIds)
    }
    // 选择数据
    let GoodsLength = ref(0)
    const handleSelect = (val: any) => {
      GoodsIds = []
      val.forEach((item: any) => GoodsIds.push(item.GoodsId))
      console.log(GoodsIds)
      GoodsLength.value = GoodsIds.length
    }
    let currentPage = ref(1)
    // 分页事件
    const handleCurrentChange = (val: any) => {
      currentPage.value = val
      emit('handleCurrentChange', val)
      // console.log(props.tableData)
    }
    // 隐藏dialog
    function changeVisibility(e: boolean) {
      dialogVisible.value = e
    }
    return {
      dialogVisible,
      GoodsIds,
      currentPage,
      tableDatas,
      GoodsLength,
      addShop,
      handleSelect,
      changeVisibility,
      handleCurrentChange,
    }
  },
})
